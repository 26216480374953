import * as React from "react"
// import { Script,
//   //useStaticQuery,  
// } from "gatsby"

import Seo from "../components/triton-default/seo"

import TritonDefaultLayout from '../layouts/triton-default/default-layout'
import sections from "../components/triton-default/sections/sections"
import Fallback from "../components/triton-default/fallback"
import GatsbyGoogleTag from "../components/triton-default/gatsby-google-tag"

//const GATSBY_GOOGLE_TAG = process.env.GATSBY_GOOGLE_TAG;

export const Head = ({ location, pageContext }) => {
  //console.debug('pages - head', { location, params, data, pageContext });
  // handle page level seo content
  const seo = {
    pageTitle: pageContext.page.seoMetaData && pageContext.page.seoMetaData.seoTitle ? pageContext.page.seoMetaData.seoTitle : pageContext.page.displayText,
    pageDescription: pageContext.page.seoMetaData && pageContext.page.seoMetaData.seoDescription ? pageContext.page.seoMetaData.seoDescription : null,
  }
  return (
    <>
      <Seo pagePath={location.pathname} title={seo.pageTitle} description={seo.pageDescription} />
      <GatsbyGoogleTag />
    </>
  )
}

const Pages = (props) => {
  //console.debug('Pages - props', props);

  if (typeof window !== 'undefined') {
    document.body.id = 'pages';
  }

  return (
    <>
      <TritonDefaultLayout subpage={props.pageContext.page.className}>
        <>
          {props.pageContext.page.sections && props.pageContext.page.sections.contentItems.length !== 0 && props.pageContext.page.sections.contentItems.map((block) => {
            //console.debug('Pages - block', block)
            const { contentItemId, contentType, } = block
            //console.debug('sections[blocktype]', sections[blocktype.metaname])
            if (contentItemId && contentType) {
              const Component = sections[contentType] || Fallback
              return <Component key={contentItemId} renderSource={props?.pageContext?.page?.className ? props.pageContext.page.className : 'pages'} {...block} />
            } else {
              return null
            }
          })}
        </>
      </TritonDefaultLayout>
    </>
  )
}

export default Pages
